<script>
import Projects from '../../components/ProjectView/Projects.vue'

export default {
  name: "ProjectPage",
  components: { 
    'projects-view': Projects
   }

};
</script>

<template>
  <projects-view />
</template>
