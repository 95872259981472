<script>
export default {
  name: "ProjectsList",

  data() { return {
    projects: [],

    filters: {
      'only-mine': false
    },

    waitResponse: false
  }},

  computed: {
    projectsCount()
    {
      return this.projects.length;
    },

    showFeedbackBox()
    {
      return !this.projectsCount && !this.waitResponse;
    },

    hasPrivilege()
    {
      return this.$session.HasAccess(['manage_projects']);
    }
  },

  created() 
  {
    this.RequestProjects();
  },

  methods: {
    RefreshProjects()
    {
      this.projects = [];
      this.RequestProjects();
    },

    RequestProjects()
    {
      this.waitResponse = true;
      const params = this.filters;
      
      this.$api.get({ path: `projects`, params }, this.OnProjectsResponse);
    },

    OnProjectsResponse({response})
    {
      const { project_list } = response.data;

      this.projects = project_list;

      this.waitResponse = false;
    },

    OpenProject(projectId)
    {
      this.$router.push(`/project/${projectId}`);
    },

    ToggleFilter(filter)
    {
      this.filters[filter] = !this.filters[filter];
      this.RefreshProjects();
    }
  },
};
</script>

<template>
  <div class="projects">

    <div class="filter-box bordered-box" v-if="$session.HasAccess(['manage_projects'])">
      <div class="column" @click="$pop.openProjectForm" v-if="hasPrivilege">
        <button class="no-bg ripple-effect">
          <icon>add</icon>
          Proiect Nou
        </button>
      </div>

      <div class="column">
        <drop-down>
          <button class="no-bg ripple-effect">
            <icon>filter_list</icon>
            {{$lan("btn_filter")}}
          </button>

          <template #content>
            <div class="box filter-list">
              <check-box class="no-bg" v-for="(value, filter) in filters" :key="filter" 
                :checked="value" 
                @check="ToggleFilter(filter)" 
                @un-check="ToggleFilter(filter)" 
              >
                {{$lan(`project-filter-${filter}`)}}
              </check-box>
            </div>
          </template>
        </drop-down>
      </div>
    </div>


    <div class="projects-grid">
      <message-box class="box project-box" v-if="showFeedbackBox" style="padding: 20px;">
        <icon>calendar_today</icon>
        Nu există nici un proiect încă
      </message-box>

      <loading-box v-if="waitResponse">
      </loading-box>

      <div v-for="(project, index) in projects" :key="index" class="box project-box" @click="OpenProject(project.id)">
        <img class="project-banner" src="/img/blue_project_banner.svg" />
        <a class="project-title">
          {{project.title}}
        </a>
        <div class="project-date">
          {{$utils.TimeStampToString(project.created)}}
        </div>
      </div>
  </div>

  </div>
</template>

<style scoped>

.projects {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-inline: 10%;
  justify-content: center;
}

.projects-grid {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: left;
}

.project-box {
  position: relative;
  border-radius: 20px;
  max-width: 300px;
  flex-grow: 1;
  min-height: 50px;
  margin: 10px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
  transition-duration: 0.3s;
  cursor: pointer;
  overflow: hidden;
  animation: zoom-in 0.1s;
}

.project-box:hover {
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.3);
}

.project-banner {
  position: relative;
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 15px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
}

.project-box:hover .project-banner {
  filter: brightness(80%);
}

.project-title {
  position: absolute;
  display: block;
  top: 40px;
  width: 100%;
  text-align: center;
  z-index: 2;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.87);
  padding-inline: 20px;
  word-break: break-all;
}

.project-date {
  color: rgb(156, 156, 156);
  margin: 10px;
  display: block;
}

.filter-list {
  overflow: hidden;
}

.filter-box {
  width: 100%;
  margin: 10px;
  display: flex;
  padding: 0;
  overflow: hidden;
}

.checkbox-body {
  width: fit-content;
  padding: 10px;
}

.icon {
  margin: 0;
}

.column {
  padding: 0;
}

button { 
  border-radius: 0;
}


@media only screen and (max-width: 900px) 
{
  .projects {
    padding: 0;
  }

  .project-box {
    width: 95%;
    max-width: 100%;
  }
}
</style>




